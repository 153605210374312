export const selectProductsCount = state => state.products.count;
export const selectProductsHasMore = state => state.products.hasMore;
export const selectProductsHasPrevious = state => state.products.hasPrevious;
export const selectIsFetching = state => state.products.isFetching;

export const selectSizes = ids => state =>
  ids
    .map(id => state.products.entities.sizes[id])
    .filter(size => Boolean(size));

export const selectSize = id => state => state.products.entities.sizes[id];

export const selectProductColorVariation = id => state => ({
  ...state.products.entities.colorVariations[id],
  sizes: selectSizes(state.products.entities.colorVariations[id]?.sizes || [])(
    state,
  ),
});

export const hasProduct = id => state =>
  Object.keys(state.products.entities.products[id] || {}).length > 0;

export const hasProductVariation = id => state =>
  Object.keys(state.products.entities.colorVariations[id] || {}).length > 0;

export const selectProductColorVariationsIds = state =>
  Object.keys(state.products.entities.colorVariations);

export const hasProductVariations = ids => state => {
  const allColorVariationsIds = Object.keys(
    state.products.entities.colorVariations,
  );

  return ids.every(id => allColorVariationsIds.includes(id));
};

export const selectProductColorVariations =
  (ids = []) =>
  state =>
    ids
      ?.filter(productColorId => hasProductVariation(productColorId)(state))
      .map(productColorId => ({
        ...state.products.entities.colorVariations[productColorId],
        sizes: selectSizes(
          state.products.entities.colorVariations[productColorId]?.sizes || [],
        )(state),
      })) || [];

export const selectProducts = ids => state =>
  ids
    .map(productId => state.products.entities.products[productId])
    .map(product => ({
      ...product,
      colorVariations: selectProductColorVariations(
        product?.colorVariations || [],
      )(state),
    }))
    .filter(product => Boolean(product));

export const selectProduct = id => state => ({
  ...state.products.entities.products[id],
  colorVariations: selectProductColorVariations(
    state.products.entities.products[id]?.colorVariations || [],
  )(state),
});

export const selectProductResults = state => state.products.result;

export const selectRelatedProducts = productId => state =>
  state.products.entities.products[productId]?.relatedProductColorIds || [];

export const selectSimilarProductsByColorId = id => state =>
  state.products.entities.similarProducts?.[id] || [];

export const selectLayoutPreference = state => state.products.layoutPreference;

export const selectOfferProductsCount = state =>
  state.products.productsCount.offerProductsCount;

export const selectSaleProductsCount = state =>
  state.products.productsCount.saleProductsCount;

export const selectLatestNewsAndLookbook = state => ({
  latestNews: state.products.latestNews,
  latestLookbook: state.products.latestLookbook,
});
